.logo {
  display: block;
  margin: 0 auto;
  width: 140px;

  img {
    width: 100%;
  }
}

.start__page {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  padding-bottom: 40px;
}

.start__title {
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.start__text {
  margin-top: 15px;
  text-align: center;
}

.start__button {
  margin: 0 auto;
  margin-top: 30px;
  width: 240px;
  height: 60px;
  text-align: center;
  background-color: #2620ff;
  align-items: center;
  align-content: center;
  border-radius: 40px;
  font-size: 18px;
  color: #fff;
  padding-top: 6px;
  border-bottom: 6px solid #8445ff;

  &:active {
    padding-top: 0;
    border-bottom: 0 solid #8445ff;
  }
}