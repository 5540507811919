/* font-family: "FontName"; */
@font-face {
  font-family: "FontName";
  src: url("../fonts/FontName/FontName.eot");
  src: url("../fonts/FontName/FontName.eot?#iefix")format("embedded-opentype"),
  url("../fonts/FontName/FontName.woff") format("woff"),
  url("../fonts/FontName/FontName.ttf") format("truetype");
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}