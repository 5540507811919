.test__page {
  padding-top: 30px;
  position: relative;
  z-index: 5;
}

.test__bg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(104,202,219);
  background: linear-gradient(129deg, rgba(104,202,219,1) 0%, rgba(236,150,217,1) 100%);
}

.count {
  justify-content: flex-end;
  margin-bottom: 20px;
}

.qnum {
  font-weight: 700;
}

.test__container {
  height: 456px;
  position: relative;
}

.test__img {
  padding-top: 60%;
  position: relative;
  overflow: hidden;
  border: 2px solid #0f7595;
  border-radius: 10px;
  transition: .6s;
  transform: translateY(50px);
  opacity: 0;
}

.test__selectcont {
  margin-top: 20px;
  transition: .6s;
  transform: translateY(50px);
  opacity: 0;

  input {
    display: none;

    &:checked + label {
      background-color: #68cadb;
      opacity: 1 !important;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  align-content: center;
  min-height: 40px;
  padding: 8px 15px;
  background-color: #fff;
  border: 1px solid #000;
  transition: .4s;

  margin-bottom: 4px;

  &.disabled {
    opacity: .7;
  }
}


////////


.test__item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;

  &.activeItem {
    visibility: visible;
    overflow: visible;
    height: auto;

    .test__img {
      transform: translateY(0);
      opacity: 1;
    }
    .test__selectcont {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.strped {
    .test__img {
      transform: translateY(50px) !important;
      opacity: 0 !important;
    }
    .test__selectcont {
      transform: translateY(50px) !important;
      opacity: 0 !important;
    }
  }
}


///////

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #1F6CC7;
  opacity: 0;
  visibility: hidden;
  transition: .4s;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.load__content {
  img {
    width: 100%;
  }
}

.load__text {
  text-align: center;
  font-size: 16px;
  color: #fff;
}


/////

.result__page {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-bottom: 24px;
  min-height: 100%;
  display: none;

  .start__text {
    margin-top: 30px;
    font-size: 15px;
  }
}

.result__item {
  padding-top: 30px;
}

.result__img {
  padding-top: 60%;
  position: relative;
  overflow: hidden;
  border: 2px solid #0f7595;
  border-radius: 10px;
}

.minb {
  margin-top: 20px;
  width: 250px;
}

.inst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100% !important;
  color: #000;
  margin-top: 30px;

  span {
    width: 100%;
    text-align: center;
  }
  
  img {
    width: 33px;
    height: 33px;
    margin-bottom: 5px;
  }
}

.result__var {
  display: none;

  &.theResult {
    display: block;
  }
}

