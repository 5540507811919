html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
body {
  overflow-x: hidden;
  position: relative;
}

* {
  box-sizing: border-box; }

a {
  text-decoration: none !important;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0; }

h1, h2, h3 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

li {
  margin: 0;
  padding: 0;
  list-style: none; }

p {
  margin: 0;
}

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  border: none;
}

select {
  outline: none;
  border: none;
}

.wrapper {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flexCentr {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.cover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contain {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hr {
  width: 100%;
}



//-------------------------//


body {
  background: rgb(104,202,219);
  background: linear-gradient(129deg, rgba(104,202,219,1) 0%, rgba(236,150,217,1) 100%);
  font-family: 'Roboto', sans-serif;
  color: #2e2e2e;
  font-size: 14px;
  line-height: 1.3;
}

.container {
  width: calc(100% - 20px);
  max-width: 480px;
}